<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:35:39
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 17:13:28
 * @FilePath: \crm-education\src\views\student\home\index.vue
-->
<template>
  <div>
    <div class="mb-10">
      <a-card :bordered="false" class="cus-card no-border">
        <template slot="title">
          <div class="section-header d-flex align-center flex-wrap">
            <h2 class="base-title fs-24">{{ gradeInfo.termName }} {{ gradeInfo.majorName }}</h2>
            <p class="fs-18 color-gray ml-10">班级：{{ gradeInfo.classCode }}（{{ gradeInfo.className }}）</p>
            <p class="fs-18 color-gray ml-20">当前：{{ gradeInfo.majorLevelText }}</p>
          </div>
        </template>
        <a-row :gutter="34">
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#4071fb',
                size: '64px',
                title: '课程',
                url: 'process-icon-kc',
                denom: gradeInfo.courseCount || 0, // 分母
                num: gradeInfo.passCount || 0 // 分子
              }"
            ></progress-bar>
          </a-col>
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <!--<progress-bar
              :options="{
                theme: '#fc9038',
                title: '学分',
                url: 'process-icon-xf',
                denom: gradeInfo.courseCredit || 0,
                num: gradeInfo.obtainCredit || 0,
                size: '64px'
              }"
            ></progress-bar>-->
          </a-col>
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#ad3afc',
                title: '最低毕业学分',
                url: 'process-icon-zdxf',
                minNum: parseInt(gradeInfo.lowestCredit) || 0,
                size: '64px'
              }"
            ></progress-bar>
          </a-col>
        </a-row>
      </a-card>
    </div>
    <div class="mb-10" v-if="examInfo && examInfo.length">
      <my-test :dataSource="examInfo"></my-test>
    </div>
    <div class="mb-10" v-if="examInfo && liveInfo.length">
      <my-live :dataSource="liveInfo"></my-live>
    </div>
    <div class="mb-10">
      <my-course
        :activeKey.sync="termActiveKey"
        :termList.sync="termList"
        :courseInfo="courseInfo"
        @refresh="initCourse"
      ></my-course>
    </div>
     <!--<a-modal :visible="!!user.isCurrentTerm && user.isCurrentTerm === 'N'" :footer="null" @cancel="handleClose">
      <p class="text-center" slot="title">通知</p>
      <p class="fs-16 fw-bold txt-indent20">
        为了创造更安全、更稳定的数字校园系统，我校近期将对平台进行全面升级和历史数据迁移。
      </p>
      <p class="fs-16 fw-bold txt-indent20">
        2021年04月01日至04月15日期间，平台将暂时无法查询历史数据（往期课程，往期课程成绩），如有相关疑问，您可咨询班主任，敬请谅解！2021年04月01日至04月15日期间，平台将暂时无法查询历史数据（往期课程，往期课程成绩），如有相关疑问，您可咨询班主任，敬请谅解！
      </p>
      <p class="fs-16 fw-bold text-right">2021年4月1日</p>
      <div class="text-center">
        <a-button class="mx-10" type="primary" @click="handleClose">知道了</a-button>
      </div>
    </a-modal> -->
    <a-modal
            :visible="enterschoolTestVisible"
            width="800px"
            :footer="false"
            :closable="false"
            :maskClosable="false"
            @cancel="enterschoolTestVisible = false"
    >
      <div>
        <h2 style="text-align: center">{{ user.studentName }}同学，您好！</h2>
        <h2 style="text-align: center">您的学籍资料已经审批通过，请按照下列要求完成入学水平测试。</h2>
        <p style="color: #fb841a;margin-top: 30px;">
          以下测试科目仅用于测试您的相关知识掌握程度，不计算学分，也不影响入学，请您认真按要求参与完成即可。
        </p>
        <div style="border-bottom: 1px solid rgb(215, 220, 220);margin-top: 30px;"></div>
        <a-list class="course-box" item-layout="horizontal" :data-source="list" :split="false">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <div style="width: 260px">
              <img src="@/assets/img/admission-test.png" alt="" />
            </div>
            <p class="py-20 fs-18 fw-bold color-gray">{{ item.subjectName }}</p>
            <p class="py-20 fs-18 fw-bold color-gray">{{item.status == 2 ? '未测试' : '已测试'}}</p>
            <div class="py-20">
              <a-button class="cus-button" type="primary"target="_blank"
                        :href="`${linkBasrUrl}/studentStudy/getEnterschoolUserTest?forId=${item.forId}&&subjectId=${item.subjectId}`"
                        v-if="item.status == 2" @click.native= "showConfirm"
              >立即测试</a-button>
            </div>
          </a-list-item>
        </a-list>
      </div>
    </a-modal>

    <!--<a-modal :visible='visible' width="50%" :closable="false">-->
      <!--<p style="text-align: center" class="fs-16"><b>{{currentMsg.informName }}</b></p>-->
      <!--<p style="text-align: center" class="fs-16">{{currentMsg.createTime}}发布</p>-->
      <!--<template slot="footer">-->
        <!--<a-button  type="primary" @click="setNoticeRead(currentMsg) " style="margin-right:42%">我已认真完成阅读</a-button>-->
      <!--</template>-->
      <!--<div v-if="currentMsg.fileList!=''">-->
        <!--<p style="color: #0f7ef1">附件：<p/>-->
        <!--<div v-for="file in currentMsg.fileList">-->
          <!--<a class="fs-12" :href="file.fileUrl">{{file.fileName}}</a>-->
          <!--<a class="fs-12" style="margin-left: 10px" target="_blank" :href="previewFile(file.fileUrl)"><a-icon type="file-text"/></a>-->
        <!--</div>-->
        <!--<a-divider/>-->
      <!--</div>-->
      <!--<p v-html="currentMsg.informContent"></p>-->
    <!--</a-modal>-->
      <a-modal :visible='visible' width="50%" :closable="false">
          <p style="text-align: center" ><b class="fs-20">{{currentMsg.informName}}</b></p>
          <p style="text-align: center" class="fs-16">{{currentMsg.createTime}}发布</p>
          <template slot="footer">
              <p style="text-align: center"><a-button  type="primary" @click="setNoticeRead(currentMsg)" v-if="currentMsg.isRead=='N'">我已认真完成阅读</a-button></p>
          </template>
          <p v-html="currentMsg.informContent"></p><br>
          <div v-if="currentMsg.fileList!=''">
              <p v-for="file in currentMsg.fileList" style="text-align: center">
                  <a class="fs-12" :href="file.fileUrl">{{file.fileName}}</a>
                  <a class="fs-12" style="margin-left: 10px" target="_blank" :href="previewFile(file.fileUrl)"><a-icon type="file-text"/></a>
              </p>
          </div>
      </a-modal>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
import ProgressBar from '@/components/business/progressbar'
import MyTest from './c/MyTest'
import MyLive from './c/MyLive'
import MyCourse from './c/MyCourse'
import api from '@/api'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'student_home',
  components: {
    ProgressBar,
    MyTest,
    MyLive,
    MyCourse,
  },
  data() {
    return {
      gradeInfo: {},
      examInfo: [],
      liveInfo: [],
      courseInfo: {},
      termList: [],
      termActiveKey: '',
      enterschoolTestVisible: false,
      list: [],
      visible:false,
      currentMsg:{},
      //弹框通知的开始下标
      currentIndex:0,
      //弹框数组的length
      currentLength:0,
      noticeList:[]
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations(['setNoReadMsgCount','setOStudId']),
    async init() {
      //先校验是否提示进行入学测试
      this.checkEnterschoolUser();
      this.initGrade()
      this.initExam()
      await this.initTermDicts()
      this.initLive()
      this.initCourse()
      this.getNoReadCount()
      this.getForceAlertNotice();
    },
    async initGrade() {
      let res = await api.student.getGrade()
      if (res.code === 200) {
        this.gradeInfo = res.data
      }
    },
    async initExam() {
      let res = await api.student.getExam()
      if (res.code === 200) {
        this.examInfo = res.data || []
      }
    },
    async initLive() {
      const data = {
        selectType: '1',
        termId: this.termActiveKey
      }
      let res = await api.student.getLive(data)
      if (res.code === 200) {
        this.liveInfo = res.data
      }
    },
    async initCourse() {
      const data = {
        termId: this.termActiveKey
      }
      let res = await api.student.getCourse(data)
      if (res.code === 200) {
        this.courseInfo = res.data
      }
    },
    async initTermDicts() {
      let termRes = await api.student.getTerm()
      if (termRes.code === 200 && termRes.data.length > 0) {
        this.termList = termRes.data
        this.termActiveKey = termRes.data[0].termId
        let temp  = termRes.data.filter(item => item.currentTeaching === 'Y');
        if(temp!=null&&temp.length>0){
            this.termActiveKey = temp[0].termId
        }
      }
    },
    async getNoReadCount() {
      const { data = {} } = await api.student.getNoReadCount()
      this.setNoReadMsgCount(data.noReadCount || 0)
    },
    async getForceAlertNotice(){
      //获取当前学生强制弹框的未读通知
      const data =await api.student.getForceAlertNotice();
      if (data.code === 200 && data.data.length > 0) {
        this.noticeList = data.data;
        this.currentMsg = data.data[0];
        this.visible = true;
        this.currentLength = data.data.length;
      }
    },
    async setNoticeRead(item){
      const data = {
        informUserId: item.informUserId
      }
      let res = await api.student.setNoticeRead(data)
      const flag = this.currentIndex+1>=this.currentLength ? false:true;
      if (res.code === 200 && flag) {
        this.currentIndex = this.currentIndex+1
        this.currentMsg = this.noticeList[this.currentIndex];
        return;
      }
      this.visible = !this.visible
      this.getNoReadCount()
    },
    previewFile(url){
      return 'http://ow365.cn/?info=3&i=25157&furl='+url;
    },
    handleClose() {
      localStorage.setItem('isCurrentTerm', 'Y')
      this.user.isCurrentTerm = 'Y'
    },
    async checkEnterschoolUser() {
        // 校验是否提示进行入学测试
        let res = await api.student.checkEnterschoolUser()
        if (res.code === 200) {
            if (res.data === 'Y') {
                this.enterschoolTestVisible = true
                this.getEnterSchoolUser()
            }
        }
    },
    async getEnterSchoolUser() {
        let res = await api.student.getEnterSchoolUser()
        this.list = res.data
        this.linkBasrUrl = process.env.VUE_APP_TEST_LINK_BASE_URL
    },
    showConfirm() {
        this.$confirm({
            title: '您是否完成入学测试?',
            content: '',
            okText: '是',
            cancelText: '否',
        onOk() {
            location.reload();
        },
        onCancel() {
        },
    class: 'test',
    });
    },
    handleOk(){
      this.visible = !this.visible;
    }
  }
}
</script>
