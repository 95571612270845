<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 10:56:40
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 16:53:21
 * @FilePath: \crm-education\src\views\student\home\c\MyTest.vue
-->
<template>
  <a-card :bordered="false" class="cus-card no-border">
    <template slot="title">
      <div class="section-header dec d-flex align-center justify-between flex-wrap">
        <h2 class="base-title fs-18">我的考试</h2>
        <p class="fs-14 color-gray link" @click="$router.push({ name: 'student_my_exam' })">查看更多</p>
      </div>
    </template>
    <a-table
      class="cus-table fs-14"
      bordered
      :data-source="dataSource"
      :columns="columns"
      :pagination="false"
      rowKey="examMode"
      v-if="dataSource.length > 0"
    ></a-table>
    <!-- <a-empty v-if="!dataSource.length"> </a-empty> -->
  </a-card>
</template>

<script>
export default {
  name: 'home_my_test',
  data() {
    return {
      columns: [
        {
          title: '考试科目',
          dataIndex: 'examSubject',
          width: '18%',
          align: 'center'
        },
        {
          title: '考试方式',
          dataIndex: 'examMode',
          width: '18%',
          align: 'center'
        },
        {
          title: '考试时间',
          dataIndex: 'examTime',
          width: '18%',
          align: 'center'
        },
        {
          title: '考试成绩',
          dataIndex: 'point',
          width: '18%',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'examState',
          width: '18%',
          align: 'center'
        },
        {
          title: '操作',
          width: '8%',
          align: 'center',
          customRender: (value, row, index) => {
            return (
              <a-button class="cus-button" size="small" type="primary">
                进入
              </a-button>
            )
          }
        }
      ]
    }
  },
  props: {
    dataSource: {
      required: true,
      type: Array,
      default: () => []
    }
  }
}
</script>
