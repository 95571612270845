<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 11:48:10
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 17:08:15
 * @FilePath: \crm-education\src\views\student\home\c\MyLive.vue
-->
<template>
  <a-card :bordered="false" class="cus-card no-border">
    <template slot="title">
      <div class="section-header dec d-flex align-center justify-between flex-wrap">
        <h2 class="base-title fs-18">近期直播</h2>
        <p class="fs-14 color-gray link" @click="$router.push({ name: 'student_my_study', query: { activeKey: '2' } })">
          查看更多
        </p>
      </div>
    </template>
    <div>
      <a-row :gutter="30">
        <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="(item, i) in dataSource" :key="i">
          <panel-live :options="item"></panel-live>
        </a-col>
      </a-row>
      <!-- <a-empty v-if="!dataSource.length"> </a-empty> -->
    </div>
  </a-card>
</template>

<script>
import PanelLive from '@/components/business/panel/Live'

export default {
  name: 'home_live',
  components: {
    PanelLive
  },
  props: {
    dataSource: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
